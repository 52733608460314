<!--
 * @Author: your name
 * @Date: 2021-08-16 16:00:37
 * @LastEditTime: 2021-09-07 14:48:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\main\basicPage\quality\qualityInspection.vue
-->
<template>
  <div>
    <qualityList title="质量巡检列表" :type="6"></qualityList>
  </div>
</template>
<script>
import qualityList from './qualityCommon.vue';
export default {
  components: {
    qualityList
  },
  data() {
    return {};
  }
};
</script>
